import React from 'react'
import { Badge } from '@/components/ui/badge'

import type {
  CategoryWithChildren,
  CategoryWithParentCategory,
  StoryWithKeyIndicatorsAndCategories,
} from '@aqua/db/types'

interface StoryCardBadgesPropType {
  innerMostCategory?: {
    storyId: number
    type: CategoryWithParentCategory | null | undefined
  }
  storyChild: CategoryWithChildren | undefined
  storyData: StoryWithKeyIndicatorsAndCategories
  indicatorTags?: { name: string | null | undefined }[]
  isStoryPage?: boolean
}

const StoryCardBadges = ({
  innerMostCategory,
  storyChild,
  storyData,
  indicatorTags,
  isStoryPage = false,
}: StoryCardBadgesPropType) => {
  return (
    <>
      {!isStoryPage && (
        <div className='flex w-full flex-wrap gap-1 pb-5 pl-6 '>
          <Badge
            key={0}
            style={{
              backgroundColor: innerMostCategory?.type?.background ?? '#eee',
            }}
            className={`text-xs font-[500] leading-none text-[#26366b]`}
          >
            {storyChild?.name ?? ''}
          </Badge>
          {storyData.storyCategories.slice(0, 5).map((item, index) => {
            return (
              <Badge
                key={index}
                className={`bg-[#e2e8f0] text-xs font-normal text-[#26366b]`}
              >
                {item.category.name}
              </Badge>
            )
          })}
          {storyData.storyCategories.length > 5 && (
            <Badge
              key={storyData.storyCategories.length + 1}
              className={`bg-[#e2e8f0] text-xs font-normal text-[#26366b]`}
            >
              +{storyData.storyCategories.length - 5}
            </Badge>
          )}
        </div>
      )}
      {isStoryPage && indicatorTags && (
        <div className='flex h-max w-full flex-row flex-wrap gap-4 px-5 pt-10'>
          <Badge
            key={0}
            style={{
              backgroundColor: innerMostCategory?.type?.background ?? '#eee',
            }}
            className={`text-[16px] font-normal leading-none text-[#26366b]`}
          >
            {storyChild?.name ?? ''}
          </Badge>
          {storyData.storyCategories.map((item, index) => {
            return (
              <>
                <Badge
                  key={index + 1}
                  className={`bg-[#e2e8f0] text-[16px] font-normal text-[#26366b]`}
                >
                  {item.category.name}
                </Badge>
              </>
            )
          })}
          {indicatorTags &&
            indicatorTags.length > 0 &&
            indicatorTags.map((tag, index) => {
              return (
                <>
                  {tag.name && (
                    <Badge
                      key={index + storyData.storyCategories.length + 2}
                      className={`bg-[#e2e8f0] text-[16px] font-normal text-[#26366b]`}
                    >
                      {tag.name}
                    </Badge>
                  )}
                </>
              )
            })}
        </div>
      )}
    </>
  )
}

export default StoryCardBadges
