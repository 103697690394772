import { useMemo } from 'react'

import type { CategoryWithChildren } from '@aqua/db/types'

export const useCategoryFinder = (
  categories: CategoryWithChildren[],
  typeId: number,
) =>
  useMemo(
    () => categories.find((cat) => cat.typeId === typeId)?.children ?? [],
    [categories, typeId],
  )
