import React from 'react'

interface StoryCardCountryAndYearPropType {
  country: string | null | undefined
  startYear: number | null | undefined
  endYear: number | null | undefined
}
const StoryCardCountryAndYear = ({
  country,
  startYear,
  endYear,
}: StoryCardCountryAndYearPropType) => {
  return (
    <div className='ml-auto hidden flex-col gap-y-1 text-right sm:flex'>
      <p className='inline-flex text-[16px] font-[500] text-[#3182ce]'>
        {country ? country : 'No Country'}
      </p>
      <div className='inline-flexmt-2 inline-flex flex-wrap text-right text-[16px] font-semibold text-[#718096]'>
        {(startYear ?? '') + '-' + (endYear ?? '')}
      </div>
    </div>
  )
}

export default StoryCardCountryAndYear
