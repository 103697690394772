import { useMemo } from 'react'

import type { StoryKeyIndicatorsWithValues } from '@aqua/db/types'

import { ExpressedAs } from '~/enums/enums'
import * as BaseLineIcon from '/public/svg/baseLine.svg'
import * as ArrowDownIcon from '/public/svg/godownArrow.svg'
import * as ArrowUpIcon from '/public/svg/goupArrow.svg'
import * as SingleMeasurementIcon from '/public/svg/singleMeasurement.svg'

export const useIndicatorIcon = (
  isImpact: boolean,
  indicator: StoryKeyIndicatorsWithValues | undefined,
  sign: string,
) => {
  return useMemo(() => {
    if (isImpact && indicator) {
      if (indicator.expressedAs === ExpressedAs.SingleValue) {
        return SingleMeasurementIcon
      }
      return sign === '+' ? ArrowUpIcon : ArrowDownIcon
    }
    return BaseLineIcon
  }, [indicator?.expressedAs, isImpact, sign])
}
