import { useCallback, useMemo } from 'react'

import type { StoryWithKeyIndicatorsAndCategories } from '@aqua/db/types'

import { useImpactCheck } from './useImpactCheck'

export const useStoryYearRange = (
  story: StoryWithKeyIndicatorsAndCategories,
) => {
  const getYear = useCallback(
    (isMin: boolean) => {
      const yearValues = story.storyKeyIndicators?.[0]?.values.map(
        (value) => value.year ?? 0,
      )
      const year = isMin
        ? Math.min.apply(null, yearValues ?? [])
        : Math.max.apply(null, yearValues ?? [])
      return Number(year?.toString().slice(0, 4)) ?? undefined
    },
    [story.storyKeyIndicators],
  )

  const isImpact = useImpactCheck(story.storyType)

  return useMemo(() => {
    const startYear = isImpact ? story.startYear : getYear(true)
    const endYear = isImpact ? story.endYear : getYear(false)

    return {
      startYear,
      endYear,
    }
  }, [getYear, isImpact, story.endYear, story.startYear])
}
