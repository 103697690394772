import React from 'react'
import Image from 'next/image'

import type {
  CategoryWithParentCategory,
  StoryKeyIndicatorsWithValues,
} from '@aqua/db/types'

interface StoryCardStatsPropType {
  innerMostCategory?: {
    storyId: number
    type: CategoryWithParentCategory | null | undefined
  }
  storyImage: typeof import('*.svg')
  sign: string
  change: string
  variable: string | null | undefined
  indicator: StoryKeyIndicatorsWithValues | undefined
}

const StoryCardStats = ({
  innerMostCategory,
  storyImage,
  sign,
  change,
  variable,
  indicator,
}: StoryCardStatsPropType) => {
  return (
    <div className='rounded-bl-4 rounded-tl-4 hidden w-28 sm:block'>
      <div
        style={{
          backgroundColor: innerMostCategory?.type?.background ?? '#fff',
        }}
        className={`flex h-full w-full flex-col items-center justify-center p-3`}
      >
        <div className='mb-[6px] flex h-8 min-h-[32px] w-8 min-w-[32px] justify-center'>
          <Image
            src={storyImage}
            alt={sign === '+' ? 'arrow up' : 'arrow down'}
            className='h-8 min-h-[32px] w-8 min-w-[32px]'
          />
        </div>
        <div className='text-[#22543d]'>
          {change !== '0' && (
            <>
              <p className='w-full text-center text-[16px] font-bold leading-6   '>
                {change}
              </p>
              <p className='text- w-full text-center text-[8px] font-normal leading-3'>
                {indicator?.unit ?? ''}
              </p>
            </>
          )}
          <p className='mt-2 w-full text-center text-xs font-semibold leading-4'>
            {variable}
          </p>
        </div>
      </div>
    </div>
  )
}

export default StoryCardStats
