export enum ExpressedAs {
  Percentage = 0,
  AbsoluteChange,
  SingleValue,
}

export enum StoryTypes {
  Impact = 0,
  Baseline,
}

export enum StoryStatus {
  Draft = 0,
  Published = 1,
}
