import { useMemo } from 'react'

import type {
  CategoryWithChildren,
  StoryWithKeyIndicatorsAndCategories,
} from '@aqua/db/types'

import { useCategoryFinder } from './useCategoryFinder'
import { useChangeCalculation } from './useChangeCalculation'
import { useCountry } from './useCountry'
import { useImpactCheck } from './useImpactCheck'
import { useIndicatorFinder } from './useIndicatorFinder'
import { useIndicatorIcon } from './useIndicatorIcon'
import { useStoryYearRange } from './useStoryYearRange'

export const useStory = (
  storyData: StoryWithKeyIndicatorsAndCategories,
  categories: CategoryWithChildren[],
) => {
  const isImpact = useImpactCheck(storyData.storyType)
  const indicator = useIndicatorFinder(storyData.storyKeyIndicators)

  const { value: change, sign } = useChangeCalculation(indicator, isImpact)

  const indicatorCategory = useCategoryFinder(categories, 11)

  const highlightedCategory = useMemo(() => {
    return indicatorCategory
      .find((option) =>
        option.children?.some((child) => Number(child.id) === indicator?.type),
      )
      ?.children?.find((child) => Number(child.id) === indicator?.type)
  }, [indicatorCategory, indicator?.type])

  // if (!indicator) {
  //   throw new Error(
  //     `indicator of type StoryKeyIndicatorsWithValues is undefined. This is required to find indicatorIcon`,
  //   )
  // }

  const indicatorIcon = useIndicatorIcon(isImpact, indicator, sign)

  const country = useCountry(categories, storyData.storyCategories)

  const { startYear, endYear } = useStoryYearRange(storyData)

  return {
    country,
    indicator,
    indicatorIcon,
    highlightedCategory,
    change,
    sign,
    indicatorCategory,
    yearRange: {
      startYear,
      endYear,
    },
  }
}
