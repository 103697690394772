import { useCallback, useMemo } from 'react'

import type { StoryKeyIndicatorsWithValues } from '@aqua/db/types'

import { ExpressedAs } from '~/enums/enums'

export const useChangeCalculation = (
  indicator: StoryKeyIndicatorsWithValues | undefined,
  isImpact: boolean,
) => {
  const calculateChange = useCallback(
    (indicator: StoryKeyIndicatorsWithValues) => {
      const before =
        Number(indicator.values.find((x) => x.year === 0)?.value) ?? 0
      const after =
        Number(indicator.values.find((x) => x.year === 1)?.value) ?? 0
      const sign = after - before > 0 ? '+' : '-'

      switch (indicator?.expressedAs) {
        case ExpressedAs.AbsoluteChange:
          return {
            value:
              sign +
              Math.abs(after - before)
                .toFixed(5)
                .replace(/\.?0+$/, '')
                .toString(),
            sign,
            before,
            after,
          }
        case ExpressedAs.Percentage: {
          const percentage = ((after - before) / before) * 100
          if (isNaN(percentage)) {
            return {
              value: '0%',
              sign,
              before,
              after,
            }
          } else {
            return {
              value: percentage.toFixed(5).replace(/\.?0+$/, '') + '%',
              sign,
              before,
              after,
            }
          }
        }
        case ExpressedAs.SingleValue:
          return {
            value: sign + after.toString(),
            sign,
            before,
            after,
          }
        default:
          return {
            value: '',
            sign,
            before,
            after,
          }
      }
    },
    [],
  )

  return useMemo(() => {
    if (isImpact && indicator) {
      return calculateChange(indicator)
    }
    return { value: '0', sign: '+', before: 0, after: 0 }
  }, [indicator, isImpact, calculateChange])
}
