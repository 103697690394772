import React from 'react'

const StoryCardSummary = ({ summary }: { summary: string | null }) => {
  return (
    <p className='max-w-full  text-left text-lg font-semibold text-black sm:max-w-[80%]'>
      {summary ?? ''}
    </p>
  )
}

export default StoryCardSummary
