import React from 'react'
import Image from 'next/image'

import type {
  CategoryWithParentCategory,
  StoryKeyIndicatorsWithValues,
} from '@aqua/db/types'

interface StoryCardStatsMobilePropType {
  innerMostCategory?: {
    storyId: number
    type: CategoryWithParentCategory | null | undefined
  }
  storyImage: typeof import('*.svg')
  sign: string
  change: string
  variable: string | null | undefined
  indicator: StoryKeyIndicatorsWithValues | undefined
  country: string | undefined
  startYear: number | undefined | null
  endYear: number | undefined | null
}
const StoryCardStatsMobile = ({
  innerMostCategory,
  storyImage,
  sign,
  change,
  variable,
  indicator,
  country,
  startYear,
  endYear,
}: StoryCardStatsMobilePropType) => {
  return (
    <div className='flex flex-row sm:hidden '>
      <div className='rounded-bl-1 rounded-tl-1 w-1/2'>
        <div
          style={{
            backgroundColor: innerMostCategory?.type?.background ?? '#fff',
          }}
          className={`flex h-full w-full flex-row items-center justify-center p-3`}
        >
          <div className='mb-[6px] flex h-8 min-h-[32px] w-8 min-w-[32px] justify-center'>
            <Image
              src={storyImage}
              alt={sign === '+' ? 'arrow up' : 'arrow down'}
              className='min-h-[32px] min-w-[32px]'
            />
          </div>
          <div className='text-[#22543d]'>
            {change !== '0' && (
              <>
                <p className='w-full text-center text-[16px] font-bold leading-6   '>
                  {change}
                </p>
                <p className='text- w-full text-center text-[8px] font-normal leading-3'>
                  {indicator?.unit ?? ''}
                </p>
              </>
            )}
            <p className='mt-2 w-full text-center text-xs font-semibold leading-4'>
              {variable}
            </p>
          </div>
        </div>
      </div>
      <div className='ml-auto flex flex-col gap-y-1 px-4 py-3 text-right'>
        <p className='inline-flex text-[16px] font-[500] text-[#3182ce]'>
          {country ? country : 'No Country'}
        </p>
        <div className='inline-flex flex-wrap text-right text-[16px] font-semibold text-[#718096]'>
          {(startYear ?? '') + '-' + (endYear ?? '')}
        </div>
      </div>
    </div>
  )
}

export default StoryCardStatsMobile
