import { useMemo } from 'react'

import type { CategoryWithChildren, StoryCategoryDetail } from '@aqua/db/types'

import { useCategoryFinder } from './useCategoryFinder'

export const useCountry = (
  categories: CategoryWithChildren[],
  storyCategories: StoryCategoryDetail[],
) => {
  const countryCategory = useCategoryFinder(categories, 1)

  return useMemo(() => {
    if (!storyCategories || storyCategories.length === 0) {
      return ''
    }

    const countries = countryCategory?.filter((country) =>
      storyCategories.some(
        (storyCategory) => storyCategory.categoryId === Number(country.id),
      ),
    )

    const countryNamesString =
      countries
        ?.map(
          (country, index) =>
            `${country.name}${index === countries.length - 1 ? '' : ' - '}`,
        )
        .join('') ?? ''

    return countryNamesString
  }, [storyCategories])
}
